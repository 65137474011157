/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Enums for trackEvent
 */
export enum ApplicationInsightsEventEnum {
  /**
   * Button that opens the report link in whatsapp
   */
  OPEN_REPORT_LINK_IN_WHATSAPP = "OPEN_REPORT_LINK_IN_WHATSAPP",

  /**
   * Opens the report initialize dialog
   */
  OPEN_REPORT_INITIALIZE_DIALOG = "OPEN_REPORT_INITIALIZE_DIALOG"
}
