var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "mx-2",
          staticStyle: {
            display: "flex",
            "align-content": "center"
          },
          style: _vm.isPlainText
            ? "margin-bottom: -16px"
            : _vm.isEditDescription
            ? "margin-bottom: -38px"
            : "margin-bottom: -16px"
        },
        [
          _vm.subtitle
            ? _c(
                "small",
                {
                  staticClass: "ml-1"
                },
                [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
              )
            : _vm._e(),
          _c("v-spacer"),
          !_vm.isEditDescription
            ? _c("v-btn", {
                attrs: {
                  elevation: 0,
                  text: "",
                  "x-small": ""
                },
                domProps: {
                  textContent: _vm._s(_vm.$t("edit"))
                },
                on: {
                  click: _vm.startEditDescription
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isPlainText
        ? _c("v-textarea", {
            staticClass: "mx-2 mt-4",
            staticStyle: {
              height: "height"
            },
            attrs: {
              loading: _vm.isLoadingDescription,
              readonly: !_vm.isEditDescription || _vm.isLoadingDescription,
              outlined: _vm.outlined
            },
            model: {
              value: _vm.descriptionCopy,
              callback: function callback($$v) {
                _vm.descriptionCopy = $$v
              },
              expression: "descriptionCopy"
            }
          })
        : _c("template-editor", {
            key:
              "template-editor" +
              (!_vm.isEditDescription || _vm.isLoadingDescription),
            style: _vm.isLoadingDescription
              ? "margin-top: 40px; margin-bottom: -67px"
              : "",
            attrs: {
              readOnly: !_vm.isEditDescription || _vm.isLoadingDescription,
              isMobile: true,
              outlined: _vm.outlined,
              height: _vm.height
            },
            model: {
              value: _vm.descriptionCopy,
              callback: function callback($$v) {
                _vm.descriptionCopy = $$v
              },
              expression: "descriptionCopy"
            }
          }),
      _vm.isEditDescription || _vm.isLoadingDescription
        ? _c(
            "div",
            {
              staticClass: "mx-2",
              class: "".concat(_vm.isPlainText ? "mt-n5" : "mt-n7 mb-1"),
              staticStyle: {
                display: "flex"
              }
            },
            [
              _c("v-btn", {
                staticClass: "mr-2",
                attrs: {
                  elevation: 0,
                  loading: _vm.isLoadingDescription,
                  small: "",
                  color: "info"
                },
                domProps: {
                  textContent: _vm._s(_vm.$t("save"))
                },
                on: {
                  click: _vm.saveEditDescription
                }
              }),
              _c("v-btn", {
                attrs: {
                  elevation: 0,
                  small: "",
                  outlined: "",
                  color: "info"
                },
                domProps: {
                  textContent: _vm._s(_vm.$t("close"))
                },
                on: {
                  click: _vm.abortEditDesciption
                }
              })
            ],
            1
          )
        : _c("div", {
            class: _vm.isPlainText ? "mt-n8" : "mt-n15"
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }