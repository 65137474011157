








































import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import PreselectTemplateCard from "./PreselectTemplateCard.vue";
import Card from "../utility/Card.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";
import { CreateReportUrlFactory } from "@/lib/utility/createReportUrlFactory";
import { IReference } from "@/models/reference.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    Card,
    PreselectTemplateCard
  }
})
export default class PartnerReportInitializeDialogSend extends mixins(PartnerReportInitializeCardMixin) {
  @Prop()
  step!: PartnerReportInitializeDialogPagesEnum;

  @Prop()
  templateKey!: string;

  @Prop()
  url!: string;

  get partnerLanguage() {
    return PartnerModule.partner.language;
  }

  get report() {
    const createReportUrlFactory = new CreateReportUrlFactory();
    createReportUrlFactory.parseUrl(this.url);
    return createReportUrlFactory.getReport();
  }

  get to(): string[] {
    const mail = this.report.customerContact.email;

    return mail ? [mail] : [];
  }

  /**
   * Get context with partner information and information on the report that is requested from customer
   */
  get context(): ITemplateContext {
    return {
      partner: PartnerModule.partner,
      report: this.report,
      url: { report: this.url }
    };
  }

  get mailRefs(): IReference[] {
    const refs: IReference[] = [];

    if (this.report?.id) {
      refs.push({
        refType: BackendResourceEnum.REPORT,
        refId: this.report.id
      });
    }

    return refs;
  }

  loading = true;

  get partnerId() {
    return this.$route.params.partnerId || PartnerModule.partner._id;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async send() {
    await (this.$refs.templateCard as PreselectTemplateCard)?.send();
  }

  sent() {
    this.setNextStep(PartnerReportInitializeDialogPagesEnum.SUCCESS);
  }
}
