import { render, staticRenderFns } from "./HasDescription.vue?vue&type=template&id=00b54bb5&"
import script from "./HasDescription.vue?vue&type=script&lang=ts&"
export * from "./HasDescription.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VSpacer,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00b54bb5')) {
      api.createRecord('00b54bb5', component.options)
    } else {
      api.reload('00b54bb5', component.options)
    }
    module.hot.accept("./HasDescription.vue?vue&type=template&id=00b54bb5&", function () {
      api.rerender('00b54bb5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/utility/HasDescription.vue"
export default component.exports