var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex"
          }
        },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                "x-small": "",
                text: "",
                elevation: 0
              },
              on: {
                click: _vm.attachMessageToReport
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("message.attachToReport")) + " ")]
          )
        ],
        1
      ),
      _vm._l(_vm.reportReferences, function(ref, index) {
        return _c(
          "v-card",
          {
            key: "selectedAttachmentsForExport" + index,
            staticClass: "my-1",
            attrs: {
              outlined: ""
            },
            on: {
              click: function click($event) {
                return _vm.openAttachForReport(ref.refId)
              }
            }
          },
          [
            _c("refs-selected", {
              attrs: {
                value: [ref],
                partnerId: _vm.value.partnerId,
                draggable: false,
                loading: _vm.isLoadingRefs,
                removable: true,
                outlined: false,
                suggested: [],
                listMode: false,
                hideEditButton: false
              },
              on: {
                removed: function removed($event) {
                  return _vm.removeReportReference(ref)
                },
                openDetail: _vm.openReportSideCard
              }
            })
          ],
          1
        )
      }),
      _c("partner-message-detail-attach-dialog", {
        ref: "attachDialog",
        attrs: {
          value: _vm.value
        }
      }),
      _vm.selectedReport
        ? _c("partner-report-detail-side-card", {
            attrs: {
              report: _vm.selectedReport,
              loading: _vm.loadingSelectedReport
            },
            on: {
              close: function close($event) {
                _vm.selectedReport = null
              }
            }
          })
        : _vm._e(),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            loading: _vm.isRemoveDialogLoading,
            isDialogActive: _vm.isRemoveDialog,
            title: _vm.$t("message.detachAttachmentsQuestion"),
            rightText: _vm.$t("message.detach"),
            rightLoading: _vm.isRemoveDialogLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isRemoveDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isRemoveDialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.selectedReport = null
            },
            rightClick: _vm.removeAttachments
          }
        },
        _vm._l(_vm.attachmentsReferncingReport, function(attachment, index) {
          return _c("partner-message-detail-attachment-card", {
            key:
              "partner-message-detail-attachment-card" +
              attachment.name +
              index +
              "attach-reffing-report",
            attrs: {
              value: attachment,
              message: _vm.value,
              hideActions: true
            }
          })
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }