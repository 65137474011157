























































































































































import UserNameInfo from "@/components/utility/UserNameInfo.vue";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IReport } from "@/models/report.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import AssigneeUpdateMixin, { IAssigneeUpdateMixin } from "../project/AssigneeUpdateMixin.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { ActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    UserNameInfo,
    ConfirmActionDialog
  }
})
export default class PartnerReportDetailChangeProgress extends mixins(PermissionMixin, AssigneeUpdateMixin)
  implements IAssigneeUpdateMixin {
  readonly ProgressStatusEnum = ProgressStatusEnum;

  @Prop()
  value!: IReport;

  /**
   * local loading status is used here next to isProgressUpdateLoading (managed by store), because if value.progressStatus status is deleted in other dialog, this dialog should be disabled but not loading
   */
  isProgressStatusLoading = false;

  isIsFixedLoading = false;

  /**
   * Dialog asking if the damage is fixed
   */
  isIsFixedDialog = false;

  isFinishedDialog = false;

  updateLoading = false;

  get users() {
    return PartnerUserModule.paginationList;
  }

  /*
   * States in dropdown list
   */
  get availableProgressStatuses(): ProgressStatusEnum[] {
    return [
      ProgressStatusEnum.DRAFT,
      ProgressStatusEnum.NEW,
      ProgressStatusEnum.IN_PROGRESS,
      ProgressStatusEnum.FINISHED
    ];
  }

  get assignees() {
    return this.value.assignees as string[];
  }

  set assignees(value: string[]) {
    const oldAssignees = this.assignees;
    const newAssignees = value;

    this.debounceChangeAssignees(newAssignees, oldAssignees, this.value.id || this.value._id);

    this.value.assignees = value;
  }

  /**
   * @override
   * @param added
   * @param removed
   */
  async changeAssignees(added: string[], removed: string[], all: string[], documentId: string) {
    try {
      this.updateLoading = true;

      const source = {
        refType: BackendResourceEnum.REPORT,
        refId: documentId
      };

      const partnerId = this.value.partnerId;

      await this.value.updatePartial({ assignees: all });

      const promises = [];
      if (removed.length > 0) {
        const promise = new ActivityLog().createAssigneeActivity({
          partnerId,
          source,
          newAssignees: removed,
          activityType: ActivityTypeEnum.DELETE_ASSIGNEE
        });

        promises.push(promise);
      }
      if (added.length > 0) {
        const promise = new ActivityLog().createAssigneeActivity({
          partnerId,
          source,
          newAssignees: added,
          activityType: ActivityTypeEnum.CREATE_ASSIGNEE
        });

        promises.push(promise);
      }

      await Promise.all(promises);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.updateLoading = false;
    }
  }

  color(progressStatus: ProgressStatusEnum) {
    return reportStateMap.get(progressStatus)?.color || "blue-grey lighten-4";
  }

  /*
   * sends request to update value.progressStatus
   * if appropriate, sets isIsFixedLoading to true
   */
  async setProgress(progressStatus: ProgressStatusEnum) {
    if (!this.value.isFixed && progressStatus === ProgressStatusEnum.FINISHED) {
      this.isIsFixedDialog = true;
    }

    const current = this.value.progressStatus;
    if (progressStatus === current) {
      this.$log.info("Status did not change");
      return;
    }

    this.isProgressStatusLoading = true;
    await this.value.updateProgressStatus(progressStatus).catch(handleError);
    this.isProgressStatusLoading = false;
  }

  /*
   * sends request to update value.isFixed
   */
  async setIsFixed(isFixed: boolean) {
    if (isFixed && this.value.progressStatus !== ProgressStatusEnum.FINISHED) {
      this.isFinishedDialog = true;
    }

    const current = Boolean(this.value.isFixed);
    if (isFixed === current) {
      this.$log.info("isFixed did not change");
      return;
    }

    this.isIsFixedLoading = true;
    await this.value.updateIsFixed(isFixed).catch(handleError);
    this.isIsFixedLoading = false;
  }

  async markFixed() {
    this.isIsFixedLoading = true;
    await this.value.updateIsFixed(true).catch(handleError);
    this.isIsFixedLoading = false;
    this.isIsFixedDialog = false;
  }

  async markFinished() {
    this.isProgressStatusLoading = true;
    await this.value.updateProgressStatus(ProgressStatusEnum.FINISHED).catch(handleError);
    this.isProgressStatusLoading = false;
    this.isFinishedDialog = false;
  }
}
