
























































import { IHasUpdatePartial } from "@/lib/utility/data/has-update-partial.interface";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPerson } from "@/models/person.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { IUser, PartnerUser } from "@/models/user.entity";
import { $t } from "@/lib/utility/t";
import { ICompany } from "@/models/company.entity";
import TemplateEditor from "@/components/template/TemplateEditor.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

type IHasUserId = Pick<ICompany, "userId">;
type IHasTimestamp = Pick<ICompany, "timestamp">;
type IHasDescription = Pick<IPerson, "description">;
type IHasPartnerId = Pick<IPerson, "partnerId">;
type T = IHasUserId & IHasDescription & IHasPartnerId & IHasTimestamp;

@Component({
  components: { TemplateEditor },
  filters: {}
})
export default class HasDescription extends mixins(PermissionMixin) {
  @Prop()
  value!: T & IHasUpdatePartial<T, IHasDescription>;

  @Prop()
  text!: string;

  @Prop({ default: true })
  isPlainText!: boolean;

  @Prop({ default: "description" })
  prop!: string;

  @Prop({ default: "250px" })
  height!: string;

  @Prop({ default: true })
  outlined!: boolean;

  get subtitle() {
    if (this.text) {
      return this.text;
    }

    if (this.value.userId && this.userName) {
      return $t("createdOnBy", {
        date: this.value.timestamp.createdReadable,
        name: this.userName
      });
    }

    return $t("createdOn", { date: this.value.timestamp.createdReadable });
  }

  isLoadingUser = false;

  isLoadingDescription = false;

  isEditDescription = false;

  descriptionCopy = this.value[this.prop] ?? "";

  user: IUser | null = null;

  async mounted() {
    if (!this.value.userId) {
      this.user = null;
      return;
    }

    this.isLoadingUser = true;
    if (this.user?.id !== this.value.userId) {
      this.user = PartnerUserModule.maps.id.get(this.value.userId)[0];
    }

    if (this.can(ActionEnum.READ, ResourceEnum.USER) && this.user?.id !== this.value.userId) {
      const user = new PartnerUser({ partnerId: this.value.partnerId, id: this.value.userId });
      await user.fetch().catch(this.$log.error);
      this.user = user;
    }
    this.isLoadingUser = false;
  }

  get userName() {
    const user = this.user;

    let name = "";
    if (user && user.firstName && user.lastName) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  get userInitials() {
    const user = this.user;

    let name = "";
    if (user?.firstName && user?.lastName) {
      name = `${user.firstName[0]}${user.lastName[0]}`;
    }

    return name;
  }

  startEditDescription() {
    this.descriptionCopy = this.value[this.prop] ?? "";
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value[this.prop] ?? "";
    this.isEditDescription = false;
  }

  async saveEditDescription() {
    this.isLoadingDescription = true;
    await this.value
      .updatePartial({
        [this.prop]: this.descriptionCopy || null
      })
      .catch(handleError);
    this.isLoadingDescription = false;
    this.isEditDescription = false;
  }
}
