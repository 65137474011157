var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            attrs: {
              type:
                "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
            }
          })
        : _vm.report._id
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("m-header", {
                attrs: {
                  title: _vm.title,
                  subtitle: _vm.date,
                  chips: _vm.chips,
                  breadCrumbs: _vm.breadCrumbs,
                  alerts: _vm.alerts,
                  actions: _vm.actions,
                  loading: _vm.isKsrLoading
                },
                on: {
                  actionClicked: _vm.processAction,
                  alertClicked: _vm.processAction
                }
              }),
              _c(
                "v-row",
                {
                  staticClass: "d-none d-print-inline mx-4 mt-n4"
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("common.nouns.report")) +
                        " " +
                        _vm._s(_vm.report.id)
                    )
                  ])
                ]
              ),
              _vm.isUpdateProgressStatusEnabled
                ? _c("partner-report-detail-change-progress", {
                    staticClass: "px-4 pb-4",
                    model: {
                      value: _vm.report,
                      callback: function callback($$v) {
                        _vm.report = $$v
                      },
                      expression: "report"
                    }
                  })
                : _vm._e(),
              _c("v-divider"),
              _c("debug", {
                attrs: {
                  debug: _vm.report
                }
              }),
              _c("debug", {
                attrs: {
                  debug: _vm.activityLog
                }
              }),
              _vm.selectedThirdPartySystem
                ? _c("third-party-system-side-card", {
                    attrs: {
                      value: _vm.selectedThirdPartySystem
                    },
                    on: {
                      close: function close($event) {
                        _vm.selectedThirdPartySystem = null
                      }
                    }
                  })
                : _vm._e(),
              _vm.isUpdateProgressStatusEnabled
                ? _c("partner-report-detail-delete-dialog", {
                    ref: "deleteDialog",
                    attrs: {
                      hideButton: true
                    },
                    on: {
                      setReport: _vm.setReport
                    }
                  })
                : _vm._e(),
              _c("partner-report-update-report-dialog", {
                ref: "updateDialog",
                attrs: {
                  hideButton: true,
                  config: [
                    _vm.ReportUpdateTabEnum.report,
                    _vm.ReportUpdateTabEnum.accident,
                    _vm.ReportUpdateTabEnum.witness,
                    _vm.ReportUpdateTabEnum.police,
                    _vm.ReportUpdateTabEnum.contact,
                    _vm.ReportUpdateTabEnum.leasing,
                    _vm.ReportUpdateTabEnum.preExisting,
                    _vm.ReportUpdateTabEnum.body,
                    _vm.ReportUpdateTabEnum.images,
                    _vm.ReportUpdateTabEnum.insurance,
                    _vm.ReportUpdateTabEnum.broker
                  ]
                }
              }),
              _c("send-to-extern-option", {
                ref: "sendToExternDialog",
                attrs: {
                  hideButton: true,
                  thirdPartySystems: _vm.thirdPartySystems
                }
              }),
              _c("report-document-sign-request-dialog", {
                ref: "signDialog",
                attrs: {
                  report: _vm.report
                }
              }),
              _c("report-activity-share-dialog", {
                ref: "shareDialog"
              }),
              _c("report-activity-upload-dialog", {
                ref: "uploadDialog"
              }),
              _vm.isTemplateActive && _vm.canCreateMessage
                ? _c("template-dialog", {
                    ref: "templateDialog",
                    attrs: {
                      hideButton: true,
                      partnerId: _vm.partner._id,
                      context: _vm.templateDialogMethods.context,
                      to: _vm.templateDialogMethods.to,
                      from: _vm.templateDialogMethods.from,
                      refs: [
                        {
                          refId: _vm.report.id,
                          refType: _vm.ResourceEnum.REPORT
                        }
                      ]
                    }
                  })
                : _vm._e(),
              _c("ticket-create-dialog", {
                key: "ticket-create-dialog" + JSON.stringify(_vm.ticketRefs),
                ref: "ticketDialog",
                attrs: {
                  hideButton: true,
                  isIconButton: true,
                  isDialogActive: _vm.isTicketCreateDialogActive,
                  partnerIdProp: _vm.report.partnerId,
                  onStartDialog: _vm.setRefs,
                  refs: _vm.ticketRefs
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isTicketCreateDialogActive = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isTicketCreateDialogActive = $event
                  },
                  created: _vm.onTicketCreated
                }
              }),
              _c("cost-create-dialog", {
                key: "cost-create-dialog" + JSON.stringify(_vm.ticketRefs),
                ref: "costDialog",
                attrs: {
                  isDialogActive: _vm.isCostCreateDialogActive,
                  hideButton: true,
                  partnerId: _vm.report.partnerId,
                  refs: _vm.ticketRefs
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isCostCreateDialogActive = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isCostCreateDialogActive = $event
                  }
                }
              }),
              _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    title: _vm.$t(
                      "components.partner.PartnerReportDetail.summarize"
                    ),
                    hideRight: true,
                    hideLeft: true,
                    persistent: true,
                    isDialogActive: _vm.isSummaryDialogActive
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isSummaryDialogActive = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isSummaryDialogActive = $event
                    },
                    close: function close($event) {
                      _vm.isSummaryDialogActive = false
                    }
                  }
                },
                [
                  _vm.summaryLoading
                    ? _c(
                        "v-row",
                        {
                          staticClass: "fill-height ma-0 pt-2",
                          attrs: {
                            justify: "center"
                          }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: "primary"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.summary
                    ? _c("v-else", {
                        domProps: {
                          innerHTML: _vm._s(_vm.summary)
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.displayToDetailView
                        ? "scrollable virtual-scroller"
                        : "",
                      style: _vm.displayToDetailView
                        ? "height: ".concat(_vm.height)
                        : ""
                    },
                    [
                      _vm.loading
                        ? _c("v-skeleton-loader")
                        : _vm.isTimelineEnabled
                        ? _c("partner-report-detail-info-time-line-card", {
                            on: {
                              showSignDialog: _vm.showSignDialog,
                              showEmailDialog: _vm.showEmailDialog,
                              showShareDialog: _vm.showShareDialog,
                              showUploadDialog: _vm.showUploadDialog,
                              showTicketDialog: _vm.showTicketDialog
                            }
                          })
                        : _c("partner-report-detail-info-card"),
                      !_vm.isTimelineEnabled &&
                      _vm.activityLog &&
                      _vm.activityLog.length >= 1
                        ? _c(
                            "v-card",
                            {
                              attrs: {
                                flat: ""
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12"
                                      }
                                    },
                                    [
                                      _c("table-wrapper", {
                                        attrs: {
                                          title: _vm.$t(
                                            "partner.ActivityLogTable.title"
                                          ),
                                          headers: _vm.headers,
                                          allItems: _vm.activityLog
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.timestamp.created",
                                              fn: function fn(_ref) {
                                                var item = _ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "simpleDoubleDigitDate"
                                                        )(
                                                          item.timestamp.created
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.actionType",
                                              fn: function fn(_ref2) {
                                                var item = _ref2.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "partner.ActivityLogTable.actionType." +
                                                            item.actionType
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.activity",
                                              fn: function fn(_ref3) {
                                                var item = _ref3.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "partner.ActivityLogTable." +
                                                            item.activity
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.comment",
                                              fn: function fn(_ref4) {
                                                var item = _ref4.item
                                                return [
                                                  item.target
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          item.target,
                                                          function(t, i) {
                                                            return _c(
                                                              "span",
                                                              {
                                                                key: i
                                                              },
                                                              [
                                                                t.refType ===
                                                                "sign"
                                                                  ? _c(
                                                                      "router-link",
                                                                      {
                                                                        attrs: {
                                                                          to: {
                                                                            name:
                                                                              "SignRequestDetailView",
                                                                            params: {
                                                                              partnerId:
                                                                                item.partnerId,
                                                                              signRequestId:
                                                                                t.refId
                                                                            }
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              t.refId
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          290100823
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass: "fill-height",
              attrs: {
                fluid: "",
                flat: ""
              }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "justify-center": "",
                    "align-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        shrink: ""
                      }
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                          "max-height": "350px",
                          contain: ""
                        }
                      }),
                      _c(
                        "v-row",
                        {
                          staticClass: "pt-8",
                          attrs: {
                            "align-center": ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center"
                            },
                            [
                              _c("partner-report-initialize-dialog", {
                                attrs: {
                                  icon: false
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }