

































import { isImage } from "@/lib/files/ImageHelper";
import { handleError } from "@/lib/utility/handleError";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PartnerImageModule } from "@/store/modules/partner-image.store";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { MrfiktivReferenceGen, MrfiktivCreateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import Debug from "../utility/Debug.vue";
import { ActivityLog } from "@/models/activity-log.entity";

@Component({
  components: { Debug },
  filters: {}
})
export default class ImageInput extends Vue {
  @Prop()
  source!: MrfiktivReferenceGen | ThgReferenceGen;

  @Prop({ default: ReportImageType.others })
  type!: ReportImageType;

  /**
   * This setting will open the back camera on a mobile device
   */
  @Prop({ default: false })
  isCaptureEnvironment?: boolean;

  /**
   * Local image store
   */
  fileCount = 0;
  displayImages: IImageUploaded[] = [];
  isLoading = false;
  uploadingDialog = false;

  get accept() {
    return ConfigModule.imageInputTypesString;
  }

  get partner() {
    return PartnerModule.partner;
  }

  click() {
    if (this.$refs.uploader) {
      (this.$refs.uploader as HTMLInputElement).click();
    }

    this.$log.debug(this.$refs);
  }

  async onFileInputEvent(event: any) {
    this.$log.debug(event);
    const files = (this.$refs.uploader as HTMLInputElement).files;
    this.$log.debug(files);

    if (!files) {
      this.$log.warn("No files uploaded");
      return;
    }

    this.uploadingDialog = true;
    this.fileCount = files.length;
    const batchSize = 10;
    const sorted = Array.from(files).sort((a, b) => a.size - b.size);

    try {
      for (let i = 0; i < sorted.length; i += batchSize) {
        const chunk = sorted.slice(i, i + batchSize);

        for (const file of chunk) {
          const promises = [];
          promises.push(this.handleInput(file));
          await Promise.all(promises);
        }
      }

      this.$emit("upload", this.displayImages);

      await this.submit();
    } catch (error) {
      handleError(error);
    } finally {
      this.uploadingDialog = false;
    }

    // https://stackoverflow.com/questions/48309126/vue-file-upload-uploading-the-same-file-consecutively
    (this.$refs.uploader as HTMLInputElement).value = "";
  }

  /**
   * Private function to handle the on file input event.
   */
  public async handleInput(file: File) {
    if (file && isImage(file)) {
      try {
        const image = {
          file,
          type: this.type
        };

        const imageId = await PartnerImageModule.create({ partnerId: this.partner.id, ...image });
        this.displayImages.push({ isLoading: false, isUploaded: false, uploadId: imageId.id, ...image });

        if (!imageId) {
          this.$toast.error(this.$t("mixins.ReportImageSubmissionMixin.defaultError"));
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      this.$toast.error(`Fehler bei Datei ${file.name}`);
    }
  }

  async submit() {
    this.isLoading = true;
    try {
      const partnerId = this.partner.id;

      const target = this.displayImages.map(image => ({
        refId: image.uploadId,
        refType: BackendResourceEnum.IMAGE
      }));

      const data: MrfiktivCreateActivityLogDtoGen = {
        source: this.source,
        target: target,
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.CREATE_IMAGE
      };

      const activity = await new ActivityLog({ partnerId, ...data }).create();

      this.$toast.success("👍");
      this.$emit("save", activity);

      this.clear();
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Clears all local images
   */
  clear() {
    this.displayImages = [];
    this.fileCount = 0;
  }
}
