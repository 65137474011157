


































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Ref } from "vue-property-decorator";
import ConfirmActionDialog from "../../components/utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import ReportActivityBoxShareComponent, { ReportShareSteps } from "./ReportActivityBoxShareComponent.vue";

@Component({
  components: { ConfirmActionDialog, ReportActivityBoxShareComponent, Debug }
})
export default class ReportActivityShareDialog extends DarkModeHighlightMixin {
  @Ref("reportActivityShareBoxComponent")
  reportActivityShareBoxComponent!: ReportActivityBoxShareComponent;

  step = ReportShareSteps.OVERVIEW;

  setStep(step: ReportShareSteps) {
    this.step = step;
  }

  dialog = false;

  isLoading = false;

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  rightDisabled = true;

  closeDialog() {
    this.step = ReportShareSteps.OVERVIEW;
    this.isLoading = false;
    this.dialog = false;
  }

  show() {
    this.step = ReportShareSteps.OVERVIEW;
    this.isLoading = false;
    this.dialog = true;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get report() {
    return PartnerModule.report;
  }

  get isPrevious() {
    if (this.step === ReportShareSteps.OVERVIEW) {
      return true;
    }

    return (this.$refs.reportActivityShareBoxComponent as ReportActivityBoxShareComponent)?.stepActions.isPrevious;
  }

  get isNext() {
    if (this.step === ReportShareSteps.OVERVIEW) {
      return true;
    }

    return (this.$refs.reportActivityShareBoxComponent as ReportActivityBoxShareComponent)?.stepActions.isNext;
  }

  get previousLabel() {
    if (
      [
        ReportShareSteps.OVERVIEW,
        ReportShareSteps.DATE,
        ReportShareSteps.REQUEST_SUCCESS,
        ReportShareSteps.SHARING_SUCCESS
      ].includes(this.step)
    ) {
      return this.$t("close");
    }

    return (
      (this.$refs.reportActivityShareBoxComponent as ReportActivityBoxShareComponent)?.stepActions.previousLabel ??
      this.$t("back")
    );
  }

  get nextLabel() {
    if (this.step === ReportShareSteps.OVERVIEW) {
      return this.$t("create");
    }

    if (this.step === ReportShareSteps.DATE) {
      return this.$t("confirm");
    }

    if ([ReportShareSteps.REQUEST_SUCCESS, ReportShareSteps.SHARING_SUCCESS].includes(this.step)) {
      return this.$t("copy");
    }

    return (
      (this.$refs.reportActivityShareBoxComponent as ReportActivityBoxShareComponent)?.stepActions.nextLabel ??
      this.$t("next")
    );
  }

  onPrevious() {
    if (
      [ReportShareSteps.DATE, ReportShareSteps.REQUEST_SUCCESS, ReportShareSteps.SHARING_SUCCESS].includes(this.step)
    ) {
      this.closeDialog();
      return;
    }

    const on = (this.$refs.reportActivityShareBoxComponent as ReportActivityBoxShareComponent)?.stepActions.onPrevious;

    if (on) {
      on();
    }
  }

  onNext() {
    if ([ReportShareSteps.SHARING_SUCCESS, ReportShareSteps.REQUEST_SUCCESS].includes(this.step)) {
      (this.$refs
        .reportActivityShareBoxComponent as ReportActivityBoxShareComponent)?.sharedContent?.copyLinkToClipboard();

      return;
    }

    const on = (this.$refs.reportActivityShareBoxComponent as ReportActivityBoxShareComponent)?.stepActions.onNext;
    if (on) {
      on();
    }
  }
}
