













































































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { ExportModule } from "@/store/modules/export.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import PartnerReportInitializeCard from "./PartnerReportInitializeCard.vue";
import PartnerReportInitializeCardMixin, {
  PartnerReportInitializeDialogPagesEnum
} from "./PartnerReportInitializeCardMixin.vue";
import PartnerReportInitializeDialogBatch from "./PartnerReportInitializeDialogBatch.vue";
import PartnerReportInitializeDialogEmpty from "./PartnerReportInitializeDialogEmpty.vue";
import PartnerReportInitializeDialogEmptySuccess from "./PartnerReportInitializeDialogEmptySuccess.vue";
import PartnerReportInitializeDialogFromDa from "./PartnerReportInitializeDialogFromDa.vue";
import PartnerReportInitializeDialogMail from "./PartnerReportInitializeDialogMail.vue";
import PartnerReportInitializeDialogQr from "./PartnerReportInitializeDialogQr.vue";
import PartnerReportInitializeDialogSelection from "./PartnerReportInitializeDialogSelection.vue";
import PartnerReportInitializeDialogSend from "./PartnerReportInitializeDialogSend.vue";
import PartnerReportInitializeDialogSuccess from "./PartnerReportInitializeDialogSuccess.vue";
import PartnerReportInitializeDialogCreateTicket from "./PartnerReportInitializeDialogCreateTicket.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ITicket } from "@/models/ticket.entity";
import { PartnerModule } from "@/store/modules/partner";
import { ApplicationInsightsEventEnum } from "@/lib/enum/application-insights-event.enum";

@Component({
  components: {
    ContactCustomerDialog,
    PartnerReportInitializeCard,
    PartnerReportInitializeDialogSelection,
    PartnerReportInitializeDialogQr,
    PartnerReportInitializeDialogMail,
    PartnerReportInitializeDialogSend,
    PartnerReportInitializeDialogBatch,
    PartnerReportInitializeDialogEmpty,
    PartnerReportInitializeDialogSuccess,
    PartnerReportInitializeDialogEmptySuccess,
    PartnerReportInitializeDialogFromDa,
    PartnerReportInitializeDialogCreateTicket
  }
})
export default class PartnerReportInitializeDialog extends mixins(PartnerReportInitializeCardMixin) {
  @Prop({ default: true })
  icon!: boolean;

  @Prop({ default: false })
  hideButton!: boolean;

  @Prop({ default: () => new CreateReportAsPartnerDto() })
  reportDto!: CreateReportAsPartnerDto;

  dialog = false;

  step = PartnerReportInitializeDialogPagesEnum.CHOICE;

  url = "";

  /**
   * Created report. Set in initialize dialog empty
   */
  createdReport: MrfiktivReportViewModelGen | null = null;

  createdTickets: ITicket[] = [];

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
    this.$emit("close");
  }

  initializeDialog() {
    this.$appInsights.trackEvent(
      { name: ApplicationInsightsEventEnum.OPEN_REPORT_INITIALIZE_DIALOG },
      { partnerId: PartnerModule.partner.id }
    );
    this.url = "";
    this.dialog = true;
    this.step = PartnerReportInitializeDialogPagesEnum.CHOICE;
  }

  get isDaSetup() {
    return ExportModule.isDaSetup;
  }

  reset() {
    this.$emit("reset");
  }

  onCreated(created: MrfiktivReportViewModelGen) {
    this.createdReport = created;
  }

  onTicketCreated(ticket: ITicket) {
    this.createdTickets.push(ticket);
  }
}
