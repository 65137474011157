

























































import RefsSelected from "@/components/utility/RefsSelected.vue";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import { IReference } from "@/models/reference.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop, Ref } from "vue-property-decorator";
import PartnerMessageDetailAttachDialog from "@/components/partner/PartnerMessageDetailAttachDialog.vue";
import { IReport, Report } from "@/models/report.entity";
import PartnerReportDetailSideCard from "@/components/partner/PartnerReportDetailSideCard.vue";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { IPartnerMessageAttachment } from "@/models/partner-message-attachment.entity";
import { IImage } from "@/models/image.entity";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import PartnerMessageDetailAttachmentCard from "./PartnerMessageDetailAttachmentCard.vue";
import { ActivityLogDataAccessLayer } from "@/store/modules/access-layers/activity-log-service.access-layer";
import { ActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    RefsSelected,
    PartnerMessageDetailAttachDialog,
    PartnerReportDetailSideCard,
    ConfirmActionDialog,
    PartnerMessageDetailAttachmentCard
  }
})
export default class PartnerMessageDetailReportRefs extends PermissionMixin {
  @Prop()
  value!: IPartnerMessage;

  @Ref("attachDialog")
  attachDialog!: PartnerMessageDetailAttachDialog;

  isLoadingRefs = false;

  selectedReport: IReport | null = null;

  loadingSelectedReport = false;

  isRemoveDialog = false;

  isRemoveDialogLoading = false;

  reportToRemove: IReport | null = null;

  attachmentsReferncingReport: IPartnerMessageAttachment[] = [];

  get reportReferences(): IReference[] {
    return this.value?.refs?.filter(r => r.refType === ResourceEnum.REPORT) ?? [];
  }

  async removeAttachments() {
    if (!this.reportToRemove) return;
    if (!this.attachmentsReferncingReport.length) return;

    this.isRemoveDialogLoading = true;

    const promises: Promise<any>[] = [];

    const referencingImagesIds = this.attachmentsReferncingReport
      .filter(attachment => attachment.isImage)
      .map((attachment: IPartnerMessageAttachment) => attachment.ref?.refId)
      .filter(imageId => !!imageId) as string[];

    const remainingImages = Object.values(this.reportToRemove.images)
      .map((images: IImage[]) => images.filter(i => !referencingImagesIds.includes(i.id ?? "")))
      .flat(1)
      .map(image => image.id)
      .filter(image => !!image) as string[];

    promises.push(
      this.reportToRemove.updateReportImages({
        imageIds: remainingImages
      })
    );

    const documentAttachmentsReferencingReport = this.attachmentsReferncingReport.filter(
      attachment => attachment.isPdf
    );
    const documents = documentAttachmentsReferencingReport.map(d => d.populatedReference as ISignDocument);
    for (const document of documents) {
      const refs = document.refs.filter(r => r.refId !== this.reportToRemove?.id);
      document.refs = refs;
      promises.push(document.update());
    }

    await Promise.all(promises).catch(handleError);

    this.isRemoveDialogLoading = false;
    this.isRemoveDialog = false;
  }

  async removeReportReference(ref: IReference) {
    this.isLoadingRefs = true;
    this.openRemoveDialog(ref.refId);
    const refs = this.value?.refs?.filter(r => r.refId !== ref.refId);
    await this.value?.updatePartial({ refs }).catch(handleError);
    ActivityLogDataAccessLayer.delete(
      new ActivityLog({ id: ref.refType + this.value.id, partnerId: this.value.partnerId })
    );
    this.isLoadingRefs = false;
  }

  async openRemoveDialog(reportId: string) {
    if (this.value.attachments.filter(attachment => attachment.isImage || attachment.isPdf).length === 0) return;
    this.isRemoveDialog = true;
    this.isRemoveDialogLoading = true;
    try {
      const report = await this.getReportForId(reportId);
      this.reportToRemove = report ?? null;
      this.attachmentsReferncingReport.splice(0);
      const attachments = await this.getAttachmentsReferencingReport(report);
      this.attachmentsReferncingReport.push(...attachments);
      if (attachments.length === 0) {
        this.isRemoveDialog = false;
      }
    } catch (e) {
      handleError(e);
    }
    this.isRemoveDialogLoading = false;
  }

  attachMessageToReport() {
    this.attachDialog.open();
  }

  async openAttachForReport(reportId: string) {
    const report = await this.getReportForId(reportId);
    this.attachDialog.openForReport(report);
  }

  async getReportForId(reportId: string) {
    this.loadingSelectedReport = true;
    const report =
      ReportPaginationModule.maps.id.get(reportId)[0] ??
      (await new Report({
        partnerId: this.value.partnerId,
        id: reportId
      })
        .fetch()
        .catch(handleError));
    this.loadingSelectedReport = false;

    return report;
  }

  async getAttachmentsReferencingReport(report: IReport) {
    let attachments: IPartnerMessageAttachment[] = [];

    try {
      this.isLoadingRefs = true;
      attachments = (
        await Promise.all([
          this.getAttachedDocumentsReferencingReport(report),
          this.getAttachedImagesReferencingReport(report)
        ])
      ).flat();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingRefs = false;
    }

    return attachments;
  }

  async getAttachedDocumentsReferencingReport(report: IReport) {
    const documents = this.value.attachments.filter(attachment => attachment.isPdf);

    return (
      await Promise.all(
        documents.map(async doc => {
          if (!doc.populatedReference) await doc.populateReference(this.value);
          if (!(doc.populatedReference as ISignDocument)?.refs?.find(r => r.refId === report.id)) {
            return undefined;
          }
          return doc;
        })
      )
    ).filter(d => !!d) as IPartnerMessageAttachment[];
  }

  async getAttachedImagesReferencingReport(report: IReport) {
    const images = this.value.attachments.filter(attachment => attachment.isImage);

    return images
      .map(img => {
        const imageId = img.ref?.refId;
        if (!imageId) return null;

        const reportContainsImage = Object.values(report.images).find((images: IImage[]) =>
          images.find(image => image.id === imageId)
        );

        if (!reportContainsImage) {
          return undefined;
        }

        return img;
      })
      .filter(d => !!d) as IPartnerMessageAttachment[];
  }

  async openReportSideCard(ref: IReference) {
    this.selectedReport = null;
    const report = await this.getReportForId(ref.refId);

    this.$nextTick(() => {
      this.selectedReport = report ?? null;
    });
  }
}
