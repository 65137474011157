



















































import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { PartnerEntity } from "@/models/partnerEntity";
import { FeatureModule } from "@/store/modules/feature.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TemplateCard from "./TemplateCard.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IReference } from "@/models/reference.entity";

/**
 * Used to send a mail
 *
 * first a selection of templates is shown that can be used in the current context
 * define context by passing it in the context prop or passing a loadContext callback function that loads the context
 */
@Component({ components: { PermissionMixin, TemplateCard, ConfirmActionDialog } })
export default class TemplateDialog extends mixins(PermissionMixin) {
  @Prop({ default: true })
  fullscreen!: boolean;

  @Prop()
  width?: string;

  @Prop({ default: "mdi-email-outline" })
  icon!: string;

  @Prop({ default: false })
  large?: boolean;

  @Prop({ default: "" })
  buttonText?: string;

  @Prop({ default: true })
  outlined?: boolean;

  @Prop()
  context?: ITemplateContext;

  @Prop()
  loadContext?: () => Promise<ITemplateContext>;

  @Prop()
  partnerId?: string;

  @Prop()
  to?: string[];

  @Prop()
  from?: PartnerEntity[];

  @Prop({ default: false })
  externalLoading?: boolean;

  @Prop({ default: false })
  hideButton?: boolean;

  @Prop()
  refs?: IReference[];

  isDialogActive = false;

  show() {
    this.isDialogActive = true;
  }

  close() {
    this.isDialogActive = false;
  }

  get isFeatureActive() {
    return FeatureModule.isTemplateEnabled;
  }

  sent(messages: IPartnerMessage[] | undefined) {
    this.isDialogActive = false;
    this.$emit("sent", messages);
  }

  send() {
    (this.$refs.templateCard as TemplateCard).send();
  }
}
