var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.UPDATE,
            a: _vm.ResourceEnum.REPORT,
            field: _vm.value.partnerId
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                sm: "6",
                md: "4"
              }
            },
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  label: _vm.$t(
                    "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixed"
                  ),
                  loading: _vm.isIsFixedLoading,
                  disabled: _vm.isIsFixedLoading,
                  items: [false, true],
                  value: _vm.value.isFixed || false,
                  dense: "",
                  flat: "",
                  solo: "",
                  width: "10",
                  "hide-details": ""
                },
                on: {
                  change: _vm.setIsFixed
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function fn() {
                      return [
                        _c("v-icon", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.value.isFixed
                                  ? "mdi-bookmark-check-outline"
                                  : "mdi-bookmark-outline"
                              ) +
                              " "
                          )
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.value.isFixed
                                ? _vm.$t(
                                    "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixed"
                                  )
                                : _vm.$t(
                                    "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isNotFixed"
                                  )
                            ) +
                            " "
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item",
                    fn: function fn(_ref) {
                      var item = _ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item
                                    ? "mdi-bookmark-check-outline"
                                    : "mdi-bookmark-outline"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              item
                                ? _vm.$t(
                                    "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixed"
                                  )
                                : _vm.$t(
                                    "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isNotFixed"
                                  )
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.UPDATE,
            a: _vm.ResourceEnum.REPORT,
            field: _vm.value.partnerId
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                sm: "6",
                md: "4"
              }
            },
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  label: _vm.$t("objects.report.progressStatus"),
                  loading: _vm.isProgressStatusLoading,
                  disabled: _vm.isProgressStatusLoading,
                  items: _vm.availableProgressStatuses,
                  value: _vm.value.progressStatus,
                  dense: "",
                  flat: "",
                  solo: "",
                  "hide-details": ""
                },
                on: {
                  change: _vm.setProgress
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function fn() {
                      return [
                        _c("v-avatar", {
                          staticClass: "mr-2 white--text",
                          attrs: {
                            color: _vm.color(_vm.value.progressStatus),
                            elevation: 0,
                            size: "14"
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "enums.ProgressStatusEnum.".concat(
                                  _vm.value.progressStatus
                                )
                              )
                            ) +
                            " "
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item",
                    fn: function fn(_ref2) {
                      var item = _ref2.item
                      return [
                        _c("v-avatar", {
                          staticClass: "mr-2 white--text",
                          attrs: {
                            color: _vm.color(item),
                            elevation: 0,
                            size: "14"
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("enums.ProgressStatusEnum.".concat(item))
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.READ,
            a: _vm.ResourceEnum.USER,
            field: _vm.value.partnerId
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                sm: "6",
                md: "4"
              }
            },
            [
              _c("v-select", {
                key: "v-select-assignees" + _vm.updateLoading,
                attrs: {
                  outlined: "",
                  "item-value": "id",
                  "item-text": "firstName",
                  label: _vm.$t("project.ticket.assignees"),
                  items: _vm.users,
                  multiple: "",
                  flat: "",
                  clearable: "",
                  solo: "",
                  dense: "",
                  loading: _vm.updateLoading,
                  disabled: !_vm.canUpdateReport || _vm.updateLoading,
                  "hide-details": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function fn(_ref3) {
                      var item = _ref3.item,
                        index = _ref3.index
                      return [
                        index === 0
                          ? _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    outlined: ""
                                  }
                                },
                                "v-chip",
                                item.attrs,
                                false
                              ),
                              [
                                item.firstName && item.lastName
                                  ? _c(
                                      "v-avatar",
                                      {
                                        staticClass: "white--text",
                                        attrs: {
                                          color: "primary",
                                          small: "",
                                          left: ""
                                        }
                                      },
                                      [
                                        _c("small", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.firstName[0]) +
                                              _vm._s(item.lastName[0])
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-truncate",
                                    style:
                                      _vm.value.assignees &&
                                      _vm.value.assignees.length > 1
                                        ? "max-width: 75px;"
                                        : "max-width: 100px"
                                  },
                                  [
                                    _c("user-name-info", {
                                      attrs: {
                                        text: ""
                                          .concat(item.firstName, " ")
                                          .concat(item.lastName),
                                        item: item
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        index === 1 && _vm.value.assignees
                          ? _c(
                              "span",
                              {
                                staticClass: "grey--text "
                              },
                              [
                                _vm._v(
                                  " (+" +
                                    _vm._s(_vm.value.assignees.length - 1) +
                                    ") "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function fn(_ref4) {
                      var item = _ref4.item
                      return [
                        _c(
                          "v-list-item-avatar",
                          [
                            item.firstName && item.lastName
                              ? _c(
                                  "v-avatar",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: "primary",
                                      small: "",
                                      size: "35px",
                                      right: true
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.firstName[0]) +
                                        _vm._s(item.lastName[0]) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c("user-name-info", {
                                  attrs: {
                                    text: ""
                                      .concat(item.firstName, " ")
                                      .concat(item.lastName),
                                    item: item
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-list-item-subtitle", [
                              _vm._v(" " + _vm._s(item.userName) + " ")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.assignees,
                  callback: function callback($$v) {
                    _vm.assignees = $$v
                  },
                  expression: "assignees"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("confirm-action-dialog", {
        attrs: {
          title: _vm.$t(
            "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixedQuestion"
          ),
          subtitle: _vm.$t(
            "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixedQuestionDetail"
          ),
          isDialogActive: _vm.isIsFixedDialog,
          leftText: _vm.$t("no"),
          rightText: _vm.$t("yes"),
          rightLoading: _vm.isIsFixedLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isIsFixedDialog = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isIsFixedDialog = $event
          },
          close: function close($event) {
            _vm.isIsFixedDialog = false
          },
          leftClick: function leftClick($event) {
            _vm.isIsFixedDialog = false
          },
          rightClick: _vm.markFixed
        }
      }),
      _c("confirm-action-dialog", {
        attrs: {
          title: _vm.$t(
            "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFinishedQuestion"
          ),
          subtitle: _vm.$t(
            "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFinishedQuestionDetail"
          ),
          isDialogActive: _vm.isFinishedDialog,
          leftText: _vm.$t("no"),
          rightText: _vm.$t("yes"),
          rightLoading: _vm.isProgressStatusLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isFinishedDialog = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isFinishedDialog = $event
          },
          close: function close($event) {
            _vm.isFinishedDialog = false
          },
          leftClick: function leftClick($event) {
            _vm.isFinishedDialog = false
          },
          rightClick: _vm.markFinished
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }